<template>
    <form @submit.prevent="checkEmailAddr(email)">
      <p>Note: Only Trinity Presbyterian Church members and regular attendees are allowed access to the church directory.</p>
        <p>To register, please enter the email address the church has on file for you:</p>
        <input type="email" required placeholder="email" v-model="email">
        <button v-if="!emailEntered">Submit email address</button>
    </form>   
    
    <form @submit.prevent="handleSubmit">
        <p v-if="emailEntered">Great! Your email address is on file! Now enter a password that you want to use for future login.</p>
        <input v-if="emailEntered" type="password" required placeholder="password" v-model="password">
        <br>
        <div class="error">{{ err }}</div>
        <button v-if="emailEntered && !registrationComplete">Register</button>
    </form>
    <div v-if="registrationComplete">
      <p>Please check your email account and follow the link provided for verification.</p>
      <p>Once your email address has been verified, you will be able to login.</p>
    </div>
</template>

<script>
  /* This component will register the user with a valid email and password. A valid email
  * needs to be on file. With successful registration, the user is immediately logged out
  * since successful registration also logs the user in and we want email verification before 
  * allowing login and access to the directory. A verification email is sent when there is
  * successful registration.
  */

  import { auth } from '../firebase/config';
  import { createUserWithEmailAndPassword, sendEmailVerification, } from 'firebase/auth';

  export default {
    data() {
      return {
        email: '',
        password: '',
        err: null,
        emailEntered: false,
        registrationComplete: false,
        acceptableEmails: [
        'smfrenchva@gmail.com', 
        'samuelhenryjohnson@gmail.com',
        'eshrader@hotmail.com',
        'hjtrinitypastor@gmail.com',
        'steve@toastfrench.com',
        'steve.french@maranatha.net',
        'elizabethafrench.2004@gmail.com',
        'kris@toastfrench.com',
        'eeblackknight@gmail.com',
        'dblevins305@comcast.net',
        'jdkk36@frontiernet.net',
        'bibeysp@yahoo.com',
        'richheritagefarm@gmail.com',
        'jack.t.estoll@gmail.com',
        'melinda.estoll@gmail.com',
        'lillianfrench2000@gmail.com',
        'cody.harman@harmanllc.com',
        'abbyjohnson1997@gmail.com',
        'wycliff1995@gmail.com',
        'susanleighjohnson@gmail.com',
        'lassiep46@gmail.com',
        'madelinegracejohnson1997@gmail.com',
        'micahjamesjohnson1992@gmail.com',
        'the4lambs@gmail.com',
        'georgemccall@firstsentinelbank.com',
        'ms.jamie67@gmail.com',
        'alice24651@yahoo.com',
        'rickydsawyers@yahoo.com',
        'jbshrader@gmail.com',
        'eattghaw@hotmail.com',
        'chris_wearmouth@yahoo.com',
        'gijt111082@gmail.com',
        'davidsonl1@yahoo.com',
        '103erex@gmail.com',
        'benbolt1820@icloud.com',
        'deblutzrx@gmail.com',
        'chloemestoll@gmail.com',
        'bethanygestoll@gmail.com',
        'claire@toastfrench.com',
        'chloefrench930@gmail.com',
        'mathew.samantha.2017@gmail.com',
        'hagybonnie@yahoo.com',
        'josiah.david.m83@gmail.com',
        'jhowell11862@gmail.com',
        'jonothanlambert@gmail.com',
        'mnbargo@outlook.com',
        'petergestoll@gmail.com',
        'mollerjacqueline947@gmail.com'
        ]
      }
    },
    methods: {
      async checkEmailAddr(targetEmail) { //user has clicked on submit email address button; check the user's email and handle accordingly
        this.err = null; //reset to null in cases where there was a previous error
        if (this.acceptableEmails.includes(targetEmail)) { //is email on file?
          this.emailEntered = true; // email is on file; allow password field to now be displayed to continue with registration
        } else { //email is not on file
            this.email = ''; // clear the email field in the display
            this.emailEntered = false;
            this.err = "Sorry, but that email address is not on file. Try another email address or contact Steve.";
        }   
      },
      async handleSubmit() { //email and password entered and user has clicked on register button
        await this.signup(); 
        this.registrationComplete = true;
      },
      async signup() {
        try {
          this.err = null; //reset err in case there was a previous error displayed

          await createUserWithEmailAndPassword(auth, this.email, this.password);
          sendEmailVerification(auth.currentUser)
            .then(() => {
              auth.signOut(); //sign new user out after their account has been created
            });          
        } catch (error) {
            //this.err = error.message; //Give Firebase auth error message.
            this.err = 'Sorry, but that email address is already registered. If you believe this is an error, contact Steve.'
        } 
      } //end signup
    }
  }
</script>

<style>

</style>